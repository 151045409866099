import React from 'react'
import { PageProps } from 'gatsby'

const NotFoundPage: React.FC<PageProps> = () => (
    <div>
        <p>Sorry, page not found!</p>
    </div>
)

export default NotFoundPage
